import React from "react"
import { Card } from "antd"
import { LayoutCardEmpty, LayoutCardLoader } from "components"
import { IPolicyBundle } from "models/policies/policy"
import TravelPackageList from "components/TravelPackageList"
import { ITravelPackage } from "models/common/package"
import MotorPackageList from "components/MotorPackageList"

const CARD_TITLE = "Sections of cover"

export const PolicyCover: React.FunctionComponent<IPolicyCover> = ({
  isWorking = false,
  policyBundle
}) => {
  if (isWorking) {
    return <LayoutCardLoader title={CARD_TITLE} />
  }

  const cover = policyBundle?.product?.productItems

  if (
    policyBundle?.policyDetails.addon &&
    policyBundle.policy.lineOfBusiness === "travel"
  ) {
    return (
      <TravelPackageList
        packages={
          policyBundle.policyDetails.addon as { [key: string]: ITravelPackage }
        }
        product={policyBundle.product}
        excess={policyBundle.policyDetails.excesses.totalExcess}
      />
    )
  } else if (cover && cover.length > 0 && policyBundle?.policyDetails.addon) {
    return (
      <Card title={CARD_TITLE}>
        <MotorPackageList
          cover={cover}
          addOns={policyBundle?.policyDetails.addon}
        />
      </Card>
    )
  } else {
    return <LayoutCardEmpty title={CARD_TITLE} />
  }
}

export interface IPolicyCover {
  isWorking?: boolean
  policyBundle?: IPolicyBundle
}
