import React from "react"
import styled from "styled-components"
import { Card, List, Skeleton } from "antd"
import { IMoney } from "platform-client/types"
import { MoneyDisplay } from "components/display/MoneyDisplay"

const ListStyledFooter = styled.div`
  padding: 8px;
  display: flex;
  background-color: #fafafa;
  justify-content: space-between;
  font-weight: 600;
`

export const PolicyPremium: React.FunctionComponent<IQuotePremium> = ({
  loading = false,
  gross,
  net,
  iptPercentage,
  iptTotal
}) => (
  <Card title="Premium" loading={loading}>
    <List
      itemLayout="horizontal"
      size="small"
      footer={
        <ListStyledFooter>
          <span>Total</span>
          {gross && (
            <span>
              <MoneyDisplay value={gross} />
            </span>
          )}
        </ListStyledFooter>
      }
    >
      <List.Item style={{ padding: 8 }}>
        <Skeleton title={false} loading={!net} active>
          {net && (
            <>
              <List.Item.Meta description="Base premium" />
              <div>
                <MoneyDisplay value={net} />
              </div>
            </>
          )}
        </Skeleton>
      </List.Item>

      <List.Item style={{ padding: 8 }}>
        <Skeleton title={false} loading={!iptTotal} active>
          {iptTotal && (
            <>
              <List.Item.Meta description="Total Tax" />
              <div>
                <MoneyDisplay value={iptTotal} />
              </div>
            </>
          )}
        </Skeleton>
      </List.Item>
    </List>
  </Card>
)

export interface IQuotePremium {
  loading?: boolean
  gross?: IMoney
  iptPercentage?: number
  iptTotal?: IMoney
  net?: IMoney
}
