import { Button } from "antd"
import { useGetAppConfig } from "contexts/configuration/hooks/use-get-app-config"
import { IPolicyAction } from "models/policies/policyAction"
import React from "react"
import { useHistory } from "react-router"

export const PolicyAdjustment: React.FunctionComponent<IPolicyAdjustment> = (
  props
) => {
  const { applicationConfig } = useGetAppConfig()

  const history = useHistory()

  const quoteCoverTypes = applicationConfig?.activeLinesOfBusiness
    .map((activeLine) => {
      if (activeLine.isActive) {
        return activeLine.coverTypes.map((coverType) => ({
          ...coverType,
          ...{ id: activeLine.id, productType: activeLine.productType }
        }))
      }
      return null
    })
    .flat()
    .filter((item) => item)

  function adjustPolicy(
    policyId?: string,
    userId?: string,
    productRef?: string,
    coverType?: string,
    productId?: number,
    questionSetReferenceID?: string
  ): void {
    const isHybrid =
      quoteCoverTypes?.filter(
        (qct) => qct?.productReferenceId === productRef
      )[0]?.productType === "Hybrid"

    if (isHybrid) {
      history.push(`/policy/mta/${productId}/${policyId}`)
    } else {
      history.push(
        `/policy/adjust/${productRef}/${coverType}/${policyId}/${userId}/${
          questionSetReferenceID ?? ""
        }`
      )
    }
  }

  if (
    props.policyId &&
    props.userId &&
    props.productRef &&
    props.coverType &&
    props.policyActions &&
    props.productId
  ) {
    return (
      <Button
        onClick={() =>
          adjustPolicy(
            props.policyId,
            props.userId,
            props.productRef,
            props.coverType,
            props.productId,
            props.questionSetReferenceID
          )
        }
        disabled={
          props.policyActions.length > 0 &&
          (!props.policyActions.find(
            (pa: IPolicyAction) => pa.referenceID === "avt_wfs_policymta"
          ) ||
            !props.policyActions.filter(
              (pa: IPolicyAction) => pa.referenceID === "avt_wfs_policymta"
            )[0]?.allowed)
        }
      >
        {props.btnText || "Adjust"}
      </Button>
    )
  }
  return null
}

export interface IPolicyAdjustment {
  policyId?: string
  userId?: string
  productRef?: string
  coverType?: string
  productId?: number
  policyActions?: IPolicyAction[]
  btnText?: string
  questionSetReferenceID?: string
}
