import { useEffect, useState } from "react"
import { useToken } from "contexts/authorization/hooks/use-token"
import { useConfigurationContext } from "contexts/configuration/context"
import { useApiInfo } from "contexts/configuration/hooks/use-api-info"
interface IGetMTAPolicyURL {
  opusFinalUrl: string
}

const useGetMTAPolicyUrl = (
  productRef: string,
  productCover: string,
  id: string,
  userId: string,
  questionSetReferenceID?: string
): IGetMTAPolicyURL => {
  const [opusFinalUrl, setOpusFinalUrl] = useState<string>("")
  const { apiInfo } = useApiInfo()
  const { userToken } = useToken()
  const configContext = useConfigurationContext()

  useEffect(() => {
    if (
      configContext &&
      configContext.opus.baseOpusUrl &&
      apiInfo &&
      productRef &&
      productCover &&
      id &&
      userId &&
      userToken
    ) {
      setOpusFinalUrl(
        `${configContext.opus.baseOpusUrl}/quote/adjust?` +
          `productReference=${productRef}&` +
          `productCoverReference=${productCover}&` +
          `tenantReference=${apiInfo?.orgInfo.tenantReferenceId}&` +
          `tenantOrgReference=${apiInfo?.orgInfo.referenceId}&` +
          `policyId=${id}&` +
          `userId=${userId}&` +
          `token=${userToken}&` +
          `questionSetReferenceID=${questionSetReferenceID ?? ""}`
      )
    }
  }, [
    configContext,
    apiInfo,
    productRef,
    productCover,
    userId,
    id,
    userToken,
    questionSetReferenceID
  ])

  return {
    opusFinalUrl
  }
}

export default useGetMTAPolicyUrl
