import React from "react"
import { FileProtectOutlined } from "@ant-design/icons"
import { VAllPolicies, VMTAPolicy, VSinglePolicy, VSymphonyMTA } from "."

export const POLICIES_ROUTES = [
  {
    path: "/policies",
    component: VAllPolicies
  },
  {
    path: "/policy/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:subpage?",
    component: VSinglePolicy
  },
  {
    path: "/policy/adjust/:productRef/:productCover/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:userId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/:questionSetReferenceID?",
    component: VMTAPolicy
  },
  {
    path: "/policy/mta/:productId/:policyId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})",
    component: VSymphonyMTA
  }
]

export const POLICIES_NAVIGATION = [
  {
    title: "Policies",
    path: "/policies",
    childPaths: ["/policy"],
    submenu: false,
    icon: <FileProtectOutlined twoToneColor="#ccc" />,
    iconActive: <FileProtectOutlined twoToneColor="#1890ff" />
  }
]
