import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons"
import { List } from "antd"
import { IRoadsidePackage, ITravelPackage } from "models/common/package"
import { IProductItemOpus } from "models/quotes/productOpus"
import { capitalizeFirstLetter } from "utils/pocketknife/capitalize-first-letter"

interface IMotorPackageListProps {
  cover: IProductItemOpus[]
  addOns: {
    [key: string]: ITravelPackage | boolean | IRoadsidePackage
  }
}

const IncludedIcon = (
  <CheckCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#52c41a" />
)
const ExcludedIcon = (
  <CloseCircleTwoTone style={{ fontSize: 20 }} twoToneColor="#F5222D" />
)

const MotorPackageList: React.FC<IMotorPackageListProps> = ({
  cover,
  addOns
}: IMotorPackageListProps) => {
  const renderPackageDescription = (coverItem: IProductItemOpus) => {
    if (coverItem.referenceID === "stella_pi_roadside_assistance_pq") {
      const tieredRoadside = addOns.tieredRoadsideAssistance as IRoadsidePackage
      if (tieredRoadside) {
        if (tieredRoadside.selected) {
          return `${coverItem.description} - ${capitalizeFirstLetter(
            tieredRoadside.level
          )}`
        }
      } else if (addOns.roadsideAssistance) {
        return `${coverItem.description}`
      }
    }
    return coverItem.description
  }

  const renderPackageIcon = (coverItem: IProductItemOpus) => {
    switch (coverItem.referenceID) {
      case "stella_pi_roadside_assistance_pq":
        return (addOns.tieredRoadsideAssistance as IRoadsidePackage)
          ?.selected || addOns.roadsideAssistance
          ? IncludedIcon
          : ExcludedIcon

      case "stella_pi_hire_car_pq":
        return addOns.hireCar ? IncludedIcon : ExcludedIcon

      case "stella_pi_excess_free_windscreen_pq":
        return addOns.excessFreeWindscreen ? IncludedIcon : ExcludedIcon

      default:
        return coverItem.active ? IncludedIcon : ExcludedIcon
    }
  }

  return (
    <List
      dataSource={cover.map((coverItem) => ({
        title: renderPackageDescription(coverItem),
        content: renderPackageIcon(coverItem)
      }))}
      itemLayout="horizontal"
      size="small"
      renderItem={(item: { title: string; content: React.ReactNode }) => (
        <List.Item>
          <List.Item.Meta description={item.title} />
          <div>{item.content}</div>
        </List.Item>
      )}
    />
  )
}

export default MotorPackageList
